<template>
  <el-dialog
    title="預覽"
    :visible="true"
    width="500px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="preview-container">
      <p class="lottery-name">{{ data.name }}</p>

      <img
        v-if="!data.img && type === 'scratch'"
        class="lottery-img"
        src="@/assets/lottery/lottery-default.png"
        alt=""
      >
      <img
        v-if="type === 'spinner'"
        class="lottery-img"
        src="@/assets/lottery/spinner.png"
        alt=""
      >
      <img
        v-if="data.img && type === 'scratch'"
        class="lottery-img"
        :src="imgUrl(150, data.img)"
        alt=""
      >

      <div class="lottery-btn">{{ buttonLabel }}</div>
      <div class="info-container">
        <p class="title">活動期間</p>
        <p v-if="data.date" class="content-date">
          {{ dateFormat(data.date[0], 'YYYY/MM/DD') }} ~
          {{ dateFormat(data.date[1], 'YYYY/MM/DD') }}
        </p>

        <hr style="margin: 16px 0;">

        <div style="margin-bottom: 24px;">
          <p class="title">活動說明</p>
          <p v-html="data.description" />
        </div>

        <div v-if="data.notice">
          <p class="title">注意事項</p>
          <p v-html="data.notice" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import utilsMixin from '@/mixin/utils'
import { defineComponent, computed } from 'vue'
import { includes, get } from 'lodash'

export default defineComponent({
  name: 'PreviewDialog',
  mixins: [utilsMixin],
  props: {
    type: String,
    data: Object,
  },
  setup (props) {
    const isCostPoint = computed(() => {
      return includes(get(props.data, 'selectReq'), 'costPoint')
    })
    const buttonLabel = computed(() => {
      if (isCostPoint.value) return `使用點數 ${get(props.data, 'costPoint')} 點立即抽獎！`
      return '立即抽獎去！'
    })
    return {
      buttonLabel,
    }
  },
})
</script>

<style scoped lang="scss">
.lottery-img {
  @apply w-full w-[325px] h-[373px] rounded-[10px];
}
.preview-container {
  @apply flex flex-col items-center w-full max-w-[375px] m-auto max-h-[450px] overflow-y-auto;
}
.lottery-name {
  @apply font-bold leading-[28.96px] tracking-[1px] text-[20px] pb-[7px];
}
.lottery-btn {
  @apply bg-[#E1BD6B] py-[20px] mt-[9px] mb-[33px] rounded-[13px];
  @apply border-solid border-white border-[1px] w-[240px] text-center;
  @apply font-bold text-sub leading-[20px] tracking-[0.76px] text-white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
}

.info-container {
  @apply bg-[#31405F] text-white rounded-tl-[24px] rounded-tr-[24px];
  @apply py-[34px] px-[25px] leading-[20px] font-normal w-full;

  .title {
    @apply font-bold text-sub tracking-[2.76px] pb-[5px];
  }
}
</style>
