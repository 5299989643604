<template>
  <div>
    <PageTitle title="新增抽獎" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'RewardContentSetting' })" />
    <!-- <section> -->
    <!-- <p class="view-title">新增抽獎</p> -->
    <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reward-content-setting' }">
          抽獎內容設定
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">新增抽獎</el-breadcrumb-item>
      </el-breadcrumb>
    </section> -->

    <section class="card-container">
      <div class="card-content">
        <p class="card-title">基本設定</p>
        <el-form
          ref="form"
          label-position="top"
          :model="formData"
          :rules="formRules"
        >
          <el-form-item label="頁面圖片" prop="img">
            <UploadButton
              :img="formData.img"
              :isAvatar="true"
              @change="loadImg"
            />
          </el-form-item>
          <div class="content-col">
            <el-form-item label="抽獎名稱" prop="name">
              <el-input v-model="formData.name" placeholder="請輸入" />
            </el-form-item>
            <el-form-item label="抽獎方式" prop="mode">
              <GameModeSearch :model.sync="formData.mode" />
            </el-form-item>
          </div>
          <div class="content-col">
            <el-form-item label="抽獎活動期間" prop="date">
              <el-date-picker
                v-model="formData.date"
                type="datetimerange"
                range-separator="至"
                format="yyyy-MM-dd HH:mm"
                start-placeholder="開始日期"
                end-placeholder="結束日期"
              />
            </el-form-item>
            <el-form-item label="抽獎條件設定" prop="selectReq">
              <el-select
                v-model="formData.selectReq"
                placeholder="請選擇抽獎條件"
                multiple
                collapse-tags
              >
                <el-option
                  v-for="req in lotteryReq"
                  :key="req.type"
                  :label="req.name"
                  :value="req.value"
                />
              </el-select>

              <div class="pt-3 grid gap-15">
                <!-- 會員卡等級 -->
                <el-form-item
                  v-if="includesReq('memberCard')"
                  prop="memberCard"
                >
                  <el-select
                    ref="memberCard"
                    v-model="formData.memberCard"
                    placeholder="請選擇會員卡等級"
                    multiple
                  >
                    <el-option
                      v-for="(item, index) in levelList"
                      :key="index"
                      :label="item.name"
                      :value="item.level"
                    />
                  </el-select>
                </el-form-item>
                <!-- 會員標籤 -->
                <el-form-item v-if="includesReq('memberTag')" prop="memberTag">
                  <TagSearch
                    ref="memberTag"
                    :tags="tags"
                    :model.sync="formData.memberTag"
                    multiple
                    objKey="id"
                    :preserve-null="true"
                  />
                </el-form-item>
                <!-- 總使用次數 -->
                <el-form-item v-if="includesReq('totalUsed')" prop="totalUsed">
                  <el-input
                    ref="totalUsed"
                    v-model="formData.totalUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入單一會員總次數限制"
                    @input="inputCheck($event, 'totalUsed', 0, defaultRangeMax)"
                  />
                </el-form-item>
                <!-- 每日用次數 -->
                <el-form-item v-if="includesReq('dayUsed')" prop="dayUsed">
                  <el-input
                    ref="dayUsed"
                    v-model="formData.dayUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入單一會員每日抽獎次數"
                    @input="inputCheck($event, 'dayUsed', 0, defaultRangeMax)"
                  />
                </el-form-item>
                <el-form-item v-if="includesReq('costPoint')" prop="costPoint">
                  <el-input
                    ref="costPoint"
                    v-model="formData.costPoint"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入每次扣除的點數數量"
                    @input="inputCheck($event, 'costPoint', 0, defaultRangeMax)"
                  >
                    <template #suffix>點</template>
                  </el-input>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <div class="content-col">
            <el-form-item label="「再抽一次」按鈕延遲功能" prop="againDelay">
              <template slot="label">
                <FormItemTooltipLabel label="「再抽一次」按鈕延遲功能" :tooltipWidth="200">
                  若開啟此設定，則會員完成抽獎當下，「再抽一次」按鈕將於 4 秒後才能點擊
                </FormItemTooltipLabel>
              </template>
              <el-switch
                v-model="formData.againDelay"
                inactive-text="關閉"
                active-text="開啟"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">
          <span class="text-danger">*</span>
          活動說明
        </p>
        <quillEditor v-model="formData.description" :options="editorOption" @input="formError.description = false" />
        <p v-show="formError.description" class="text-danger text-sm" style="padding-top: 5px">請輸入資料</p>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">注意事項</p>
        <quillEditor v-model="formData.notice" :options="editorOption" />
      </div>
    </section>

    <section class="page-footer">
      <el-button type="text" @click="$router.go(-1)">取消</el-button>
      <el-button plain @click="showPreviewDialog = true">預覽</el-button>
      <el-button type="primary" @click="createMemberGame">新增</el-button>
    </section>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <PreviewDialog
      v-if="showPreviewDialog"
      :type="lotteryType"
      :data="formData"
      @close="showPreviewDialog = false"
    />
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import GameModeSearch from '@/components/Search/lottery/GameModeSearch.vue'
import PreviewDialog from './Dialog/PreviewDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import imageMixin from '@/mixin/image'
import { noEmptyRules, rangeRules, defaultRangeMax } from '@/validation/index'
import { lotteryReqConfig } from '@/config/lottery'
import { find, forEach, filter } from 'lodash'
import formUtils from '@/utils/form'
// APIs
import { MemberConfig } from '@/api/member'
import { CreateMemberGame } from '@/api/lottery/memberGame'

// Editor
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import TagSearch from '@/components/Search/TagSearch'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { usePermissions } from '@/use/permissions'
import { useTagStore } from '../Marketing/components/useTagStore'
import { useShop } from '@/use/shop'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'LotteryCreate',
  components: {
    UploadButton,
    quillEditor,
    GameModeSearch,
    PreviewDialog,
    TagSearch,
    ImageCropper,
    FormItemTooltipLabel,
  },
  mixins: [imageMixin],
  setup () {
    const { editorOption } = useEditor()
    const router = useRouter()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const { tags, fetchTagsOnMounted } = useTagStore()
    fetchTagsOnMounted()
    const showPreviewDialog = ref(false)
    const form = ref(null)
    const formError = reactive({
      description: false,
    })
    const formData = reactive({
      img: null,
      name: '',
      mode: '',
      data: '',
      selectReq: [],
      memberCard: null,
      totalUsed: null,
      dayUsed: null,
      memberTag: null,
      costPoint: null,
      againDelay: false,
      description: '',
      notice: '',
    })
    const levelList = ref([])
    const formRules = computed(() => {
      return {
      // img: [noEmptyRules()],
        name: [noEmptyRules()],
        mode: [noEmptyRules()],
        date: [noEmptyRules()],
        selectReq: [noEmptyRules()],
        memberCard: [noEmptyRules()],
        totalUsed: [noEmptyRules(), rangeRules()],
        dayUsed: [noEmptyRules(), rangeRules()],
        memberTag: [noEmptyRules()],
        costPoint: [noEmptyRules()],
      }
    })
    const useRequirementsTypePoint = computed(() => checkAction('adminView.memberGame.requirementsTypePoint'))

    const lotteryReq = computed(() => {
      if (!useRequirementsTypePoint.value) return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
      return lotteryReqConfig
    })
    const lotteryType = computed(() => {
      let type = 'scratch'
      if (formData.mode.key === 'luck-spin-wheel') type = 'spinner'
      return type
    })
    const includesReq = (type) => {
      return find(formData.selectReq, (item) => item === type)
    }
    const getRequirements = () => {
      const reqs = []
      let pass = true
      forEach(formData.selectReq, (req) => {
        const conditions = formData[req]

        console.log(req, pass)
        if (req === 'memberCard') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            levels: conditions,
          })
        }
        if (req === 'memberTag') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            tags: conditions,
          })
        }
        if (req === 'totalUsed' || req === 'dayUsed') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            times: conditions,
          })
        }
        if (req === 'costPoint') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            costAmount: conditions,
          })
        }
      })
      console.log(reqs)
      return [reqs, pass]
    }
    const inputCheck = (val, target, min, max) => {
      if (!val) [target] = min
      else if (val < min) formData[target] = min
      else if (val > max) formData[target] = max
      else formData[target] = Number(val)
    }
    //= > 建立會員抽獎
    const createMemberGame = async () => {
      let formPass = true
      if (!(await formUtils.checkForm(form.value))) formPass = false
      if (!formData.description) {
        formError.description = true
        formPass = false
      }
      if (!formPass) return

      const [reqs, pass] = getRequirements()
      if (!pass) return
      await CreateMemberGame({
        shopId: shopId.value,
        image: formData.img ? formData.img.id : undefined,
        memberGameModeId: formData.mode.id,
        name: formData.name,
        startAt: formData.date[0],
        endAt: formData.date[1],
        description: formData.description,
        notice: formData.notice,
        requirements: reqs,
        againDelay: formData.againDelay,
      })
      router.go(-1)
    }
    //= > 取得member config
    const getMemberConfig = async () => {
      const [res, err] = await MemberConfig({
        shopId: shopId.value,
      })
      if (err) {
        console.log(err)
        window.$message.error(err)
        return
      }
      levelList.value = filter(res.levels, (item) => item.level > 0)
    }
    onMounted(async () => {
      await getMemberConfig()
    })
    return {
      tags,
      formError,
      lotteryReq,
      defaultRangeMax,
      showPreviewDialog,
      editorOption,
      levelList,
      formData,
      formRules,
      lotteryType,
      includesReq,
      inputCheck,
      createMemberGame,
      form,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .card-content {
  @apply px-[22px] py-[24px];
}
::v-deep .el-form-item__label {
  line-height: unset;
}

::v-deep .ql-container.ql-snow {
  @apply min-h-[200px];
}

.page-footer {
  @apply text-right;
}

::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}

::v-deep .el-date-editor {
  @apply w-full;
}
</style>
